import { Rocket } from '@/components/svg';

export default function NotFound() {
  // Opinionated: do not record an exception in Sentry for 404
  return (
    <div className="flex flex-end p-0 flex-col items-start h-screen w-screen flex-wrap justify-end">
      <Rocket />
    </div>
  );
}
